[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-share:before {
  content: "\ea7d";
}
.icon-arrow-icon:before {
  content: "\e900";
}
.icon-arrow-left-icon:before {
  content: "\e901";
}
.icon-bullet-icon:before {
  content: "\e902";
}
.icon-close-icon:before {
  content: "\e903";
}
.icon-uniE904:before {
  content: "\e904";
}
.icon-uniE905:before {
  content: "\e905";
}
.icon-dropdown-arrow-icon:before {
  content: "\e906";
}
.icon-info-icon:before {
  content: "\e907";
}
.icon-locate-icon:before {
  content: "\e908";
}
.icon-location-icon:before {
  content: "\e909";
}
.icon-menu-icon:before {
  content: "\e90a";
}
.icon-minus-icon:before {
  content: "\e90b";
}
.icon-pin-icon:before {
  content: "\e90c";
}
.icon-play-icon:before {
  content: "\e90d";
}
.icon-plus-icon:before {
  content: "\e90e";
}
.icon-search-icon:before {
  content: "\e90f";
}
.icon-web-icon:before {
  content: "\e910";
}
.icon-uniE911:before {
  content: "\e911";
}
.icon-uniE912:before {
  content: "\e912";
}
.icon-facebook-icon:before {
  content: "\e913";
}
.icon-linkedIn-icon:before {
  content: "\e914";
}
.icon-quotation-end-icon:before {
  content: "\e915";
}
.icon-quotation-icon:before {
  content: "\e916";
}
.icon-twitter-icon:before {
  content: "\e917";
}
