@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// define 3 theme color
// mat-palette accepts $palette-name, main, lighter and darker variants
$gms-theme-primary: mat-palette($mat-indigo, 700, 300, 900);
$gms-theme-accent: mat-palette($mat-light-blue);
$gms-theme-warn: mat-palette($mat-deep-orange, A200);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$gms-theme: mat-light-theme(
    $gms-theme-primary,
    $gms-theme-accent,
    $gms-theme-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($gms-theme);

// Material overrides
mat-calendar {
    .mat-calendar-header {
        .mat-calendar-controls {
            button {
                padding: 0 0.5rem !important;
            }
        }
    }
}