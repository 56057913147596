.find-a-yard-search {
  .mat-select-placeholder {
    font-family: $font-family;
    font-size: rem-calc(16px);
    font-weight: $font-weight-normal;
    letter-spacing: 0.4px;
    line-height: rem-calc(20px);
    text-transform: none;
  }

  .mat-form-field-hide-placeholder .mat-select-placeholder {
    color: $color-text-primary;
    -webkit-text-fill-color: $color-text-primary;
  }

  .mat-form-field-wrapper .mat-select {
    width: 100%;
  }

  @media (min-width: map-get($grid-breakpoints, medium)) {
    .mat-form-field-wrapper .mat-select {
      height: rem-calc(54px);
    }

    .mat-form-field-wrapper .mat-select, .mat-form-field-wrapper .mat-input-element {
      padding-bottom: rem-calc(13px);
      padding-top: rem-calc(13px);
    }
  }
}
