.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: #2F3F90;
    }

    .mat-slide-toggle-bar {
      background-color: #EAEBF3;

      &:before {
        opacity: 1;
      }

      &:after {
        opacity: 0;
      }

      .mat-slide-toggle-thumb-container {
        transform: translate3d(27px, 0, 0);
      }
    }
  }

  .mat-slide-toggle-thumb {
    background-color: #EAEBF3;
    height: rem-calc(17px);
    width: rem-calc(17px);
  }

  .mat-slide-toggle-thumb-container {
    height: rem-calc(18px);
    left: rem-calc(5px);
    top: 1px;
    width: rem-calc(18px);
  }

  .mat-slide-toggle-bar {
    background-color: #fff;
    border: 2px #EAEBF3 solid;
    border-radius: rem-calc(24px);
    height: rem-calc(24px);
    padding: 0 rem-calc(5px);
    width: rem-calc(55px);
  
    &:before, &:after {
      bottom: 0;
      color: #2F3F90;
      display: block;
      font-family: $font-family;
      font-size: rem-calc(9px);
      font-weight: $font-weight-semi-bold;
      line-height: rem-calc(20px);
      position: absolute;
      top: 0;
      text-transform: uppercase;
    }
  
    &:before {
      content: 'ON';
      left: rem-calc(8px);
      opacity: 0;
    }
  
    &:after {
      content: 'OFF';
      right: (5px);
    }
  }
}
