.mat-form-field-underline {
  display: none;
}

.mat-form-field-wrapper .mat-form-field-label-wrapper .mat-form-field-label,
.mat-list .faux-label.mat-subheader,
.mat-list .faux-label.mat-subheader:first-child {
  font-size: rem-calc(16px);
  line-height: rem-calc(27px);
  color: $color-text-emphasis;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family;
  display: block !important;
  margin: rem-calc(0 0 2px 0);
  padding: 0;
  transform: translateY(rem-calc(-25px)) scale(1) perspective(100px) translateZ(0) !important;
  height: auto;
}

.mat-list .faux-label.mat-subheader,
.mat-list .faux-label.mat-subheader:first-child {
  transform: none !important;
}

.mat-form-field-wrapper .mat-form-field-label-wrapper .mat-form-field-label mat-label {
  font-weight: $font-weight-semi-bold;
}

.mat-form-field-wrapper .mat-select,
.mat-form-field-wrapper .mat-input-element {
  border: 1px solid $color-borders;
  font-size: rem-calc(15px);
  line-height: rem-calc(19px);
  padding: rem-calc(10px);

  &.error {
    border: 1px solid $color-error;
  }
}


.mat-list,
.mat-form-field-wrapper {
  padding-bottom: rem-calc(10px);
  margin-bottom: rem-calc(10px);
}

.mat-list {
  padding-top: 0 !important;

  .mat-radio-button {
    width: 100%;
    margin-top: rem-calc(6px);
    font-size: rem-calc(15px);
    line-height: rem-calc(25px);
    color: $color-text-primary;

    &:first-child {
      margin-top: 0;
    }
  }
}
.mat-radio-button.mat-accent {
  &.mat-radio-checked {
    .mat-radio-outer-circle {
      border-color: $color-primary;
    }

    .mat-radio-inner-circle {
      background-color: $color-primary;
    }
  }
}

.mat-focused {
  .mat-form-field-required-marker {
    color: $color-primary;
  }
}

@media (min-width: map-get($breakpoints, medium)) {
  .mat-form-field-wrapper .mat-select {
    width: 60%;
  }
}

@media (min-width: map-get($breakpoints, large)) {
  .mat-form-field-wrapper .mat-select {
    width: 45%;
  }
}
