@import './variables';
@import './mixins';

@keyframes underline {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

html * {
  font-family: $font-family;
  font-weight: $font-weight-normal;
}

// Used for the search results page when loading results
body {
  overflow-x: hidden;

  &.is-loading {
    header, .search-results__info, .search-results, .global-footer, .bottom-footer {
      filter: blur(4px);
    }
  }

  &.no-scroll {
    overflow: unset;

    main {
      display: none;
    }

    .global-footer {
      display: none;
    }

    .bottom-footer {
      display: none;
    }
  }
}

// Place those here before bootstrap is loaded
// It will place them in a <style> tag at the begining of content
.hide {
  display: none !important;
}

.manage-content-container {
  position: relative;
}

hr {
  height: 6px;
  width: 100%;
  background-color: #f4f4f4;
  margin: 50px 0;
  border: none;
}

h1,
h2,
h3,
h4 {
  color: $color-text-emphasis;
}

h1 {
  font-size: $font-size-h1;
  font-weight: $font-weight-semi-bold;
  font-family: $font-family-aleo;
  margin-bottom: 0;
  strong {
    color: $color-white;
    background-color: $color-primary;
    font-weight: $font-weight-bold;
    display: inline-block;
    margin-top: 10px;
    font-family: $font-family-aleo;
    @include font-aleo-heading-mobile();
    padding: 6px 12px 5px;
    text-shadow: none;
  }
  &.ani-active {
    u {
      &:after {
        animation-name: underline;
        animation-duration: 500ms;
      }
    }
  }
  u {
    text-decoration: none;
    font-family: $font-family-aleo;
    // border-bottom: 2px solid $color-primary;
    font-weight: $font-weight-semi-bold;
    position: relative;
    display: inline-block;
    &:after {
        display: block;
        border-bottom: 2px solid $color-primary;
        content: '';
        position: relative;
        top: -4px;
        width: 100%;
    }
  }
}

h2 {
  font-size: $font-size-h2;
  font-family: $font-family-aleo;
  font-weight: $font-weight-semi-bold;
  line-height: rem-calc(40px);
  margin: 0px 0px rem-calc(20px);
  mark {
    color: $color-white;
    background-color: $color-primary;
    font-weight: $font-weight-bold;
    display: inline-block;
    margin-top: 10px;
    font-family: $font-family-aleo;
    @include font-aleo-heading-mobile();
    padding: 6px 12px 5px;
  }
}

h3 {
  font-size: rem-calc(20px);
  font-weight: $font-weight-semi-bold;
  line-height: rem-calc(24px);
  margin-bottom: 0;
  &.ani-active {
    u {
      &:after {
        transform: scaleX(1);
    }
    }
  }
  u {
    text-decoration: none;
    font-family: $font-family-aleo;
    // border-bottom: 2px solid $color-primary;
    font-weight: $font-weight-semi-bold;
    position: relative;
    display: inline-block;
    &:after {
        display: block;
        border-bottom: 2px solid $color-primary;
        content: '';
        transform: scaleX(0);  
        transition: transform 500ms ease-in-out;
        position: relative;
        top: -4px;
        width: 100%;
    }
  }
}

p {
  font-size: rem-calc(16px);
  line-height: rem-calc(23px);
  color: $color-text-primary;
}

a {
  color: $color-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  [class*=icon-] {
    display: inline-block;
    text-decoration: none;
  }
}

.button {
  font-size: rem-calc(14px);
  padding: rem-calc(13px) rem-calc(40px);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: $letter-spacing-button;
  font-family: $font-family;
  text-align: center;
  position: relative;
  &:after {
    display: block;
    border-bottom: 3px solid $color-primary-hover;
    content: '';
    transform: scaleX(0);  
    transition: transform 200ms ease-in-out;
    position: relative;
    top: 13px;
    left: -40px;
    width: calc(100% + 80px);
  }
  &:hover {
    text-decoration: none;
  }

  &.primary {
    background-color: $color-primary;
    display: inline-block;
    color: white;
    font-weight: $font-weight-bold; 
  }

  &.secondary {
    background-color: #EAEBF3;
    color: $color-primary;
    display: inline-block;
    font-weight: $font-weight-heavy;
    &:after {
      border-color: $color-primary;
    }
  }

  &.tertiary {
    // box-shadow: 1px 2px 4px 0 rgba(0, 34, 67, 0.32);
    background-color: #fff;
    color: $color-primary;
    font-weight: $font-weight-heavy;
    &:hover {
      background-color: $color-primary;
      color: $color-white;
      border: solid 1px white;
    }
    &:active {
      background-color: $color-primary-hover;
    }
  }

  &.small {
    font-size: rem-calc(14px);
    padding: rem-calc(6px) rem-calc(15px);
  }
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}

input[type=text] {
  border: 2px solid $color-borders;
  padding: 10px;
  &:focus {
    outline: 1px solid $color-primary;
  }
}

.search-null-message {
  &__contact-list {
    p {
      font-size: rem-calc(16px);
      line-height: rem-calc(20px);
      margin: 0;
      strong {
        font-weight: $font-weight-semi-bold;
      } 
    }
  }
}

.contact-us-page {
  .rich-text {
    max-width: rem-calc(717px);
  }
}

.text-wrapper {
  margin-bottom: 50px;
}
figure.image {
  margin: 25px 0;
}
img {
  margin: 0;
}

figure.image, img {
  &.full-image, &[align="center"] {
    margin-bottom: 20px;
    max-width: none;
    width: 100%;
  }

  &.float-right, &[align="right"], &[style="float:right"] {
    float: none!important;
    //max-width: rem-calc(350px);
    padding: 0;
    width: 100%;
  }
  
  &.float-left, &[align="left"], &[style="float:left"] {
    float: none!important;
    //max-width: rem-calc(350px);
    padding: 0;
    width: 100%;
  }

  img {
    width: 100%;
  }

  figcaption {
    padding: rem-calc(10px);
    background: $color-background-light;
    border: 1px solid $color-borders;
    color: $color-text-primary;
    font-size: rem-calc(13.5px);
    line-height: rem-calc(17px);
  }
}

.ngucarousel {
  position: static !important;
}

//material styling
.mat-form-field {
  width: 100%;
}
.mat-form-field-wrapper .mat-select {
  padding: 20px;
  border-right: none;
  border-left: none;
  border-color: $color-secondary;
  border-width: 2px;
}
.mat-select-placeholder {
  text-transform: uppercase;
  color: $color-black;
  font-family: $font-family;
  font-weight: $font-weight-semi-bold;
  font-size: rem-calc(13px);
  letter-spacing: $letter-spacing-button-mobile;
}
.mat-option-text {
  text-transform: uppercase;
  color: $color-black;
  font-family: $font-family;
  font-weight: $font-weight-semi-bold;
  letter-spacing: $letter-spacing-button-mobile;
}
.mat-form-field-wrapper .mat-select {
  border-width: 2px !important;
}

dt, dd {
  display: inline-block;
  margin-left: 0;
}

.collapsed-list {
  display: block;
  list-style: none;
  padding-left: 0;
}

// icon-font declarations
@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/gms-icon-font/fonts/icomoon.eot??s398mj');
  src: url('/assets/fonts/gms-icon-font/fonts/icomoon.eot??s398mj#iefix') format('embedded-opentype'),
    url('/assets/fonts/gms-icon-font/fonts/icomoon.ttf??s398mj') format('truetype'),
    url('/assets/fonts/gms-icon-font/fonts/icomoon.woff??s398mj') format('woff'),
    url('/assets/fonts/gms-icon-font/fonts/icomoon.svg??s398mj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"]{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-icon:before {
  content: "\e900";
}
.icon-arrow-left-icon:before {
  content: "\e901";
}
.icon-bullet-icon:before {
  content: "\e902";
}
.icon-close-icon:before {
  content: "\e903";
}
.icon-directions-icon .path1:before {
  content: "\e904";
  color: rgb(234, 235, 243);
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

}
.icon-directions-icon .path2:before {
  content: "\e905";
  margin-left: -0.835em;
  color: rgb(47, 63, 144);
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

}
.icon-dropdown-arrow-icon:before {
  content: "\e906";
}
.icon-info-icon:before {
  content: "\e907";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: rem-calc(32px);
}

.icon-info-icon:hover {
  color: #6570AD;
}
.icon-locate-icon:before {
  content: "\e908";
}
.icon-location-icon:before {
  content: "\e909";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: rem-calc(32px);
}

.icon-location-icon:hover {
  color: #6570AD;
}
.icon-menu-icon:before {
  content: "\e90a";
  color: $color-primary;
}
.icon-minus-icon:before {
  content: "\e90b";
}
.icon-pin-icon:before {
  content: "\e90c";
  color: #8c95c2;
}
.icon-play-icon:before {
  content: "\e90d";
}
.icon-plus-icon:before {
  content: "\e90e";
}
.icon-search-icon:before {
  content: "\e90f";
}
.icon-web-icon .path1:before {
  content: "\e911";
  color: rgb(234, 235, 243);
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: rem-calc(-31px);

}
.icon-web-icon .path2:before {
  content: "\e912";
  margin-left: rem-calc(-31px);
  color: rgb(47, 63, 144);
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

}
.icon-pin-icon {
  font-size: rem-calc(20px);
  position: relative;
  top: 4px;
  // color: #EAEBF3;
  margin-right: 10px;
}
.icon-web-icon {
  font-size: rem-calc(30px);
  position: relative;
  top: 4px;
  // margin-right: 10px;
}
.icon-directions-icon {
  font-size: rem-calc(30px);
  position: relative;
  top: 10px;
}
.facebook:before {
  content: "\e913";
  color: #bfbfbf;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: rem-calc(22px);
}
.linkedin:before {
  content: "\e914";
  color: #bfbfbf;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: rem-calc(22px);
}
.icon-quotation-end-icon:before {
  content: "\e915";
  color: #8c95c2;
}
.icon-quotation-icon:before {
  content: "\e916";
  color: #8c95c2;
}
.twitter:before {
  content: "\e917";
  color: #bfbfbf;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: rem-calc(22px);
}

// component section overrides base

.main-content {
  padding: 0px 20px;  
}

// .home-page {
//   .grid-container {
//     margin: 30px 0px;
//   }
// }

.bulleted-content-section {
  margin: 40px 0px;
  ul{
    margin-top: 23px;
    > li {
      list-style-type: none;
      color: $color-black;
      margin-bottom: 40px;
      font-size: rem-calc(18px);
      margin-top: 0px;
      font-weight: $font-weight-bold;
      position: relative;
      &:before {
        color: $color-primary;
        content: "\e902";
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: -30px;
        top: 10px;
        font-size: rem-calc(9px);
      }

      >ul {
        padding: 0px;
        margin: 0px;
        li {
          list-style-type: none;
          color: $color-text-primary;
          font-size: rem-calc(14px);
          margin-top: 10px;
          margin-bottom: 0px;
          font-weight: $font-weight-normal;
          letter-spacing: $letter-spacing-p;
          line-height: rem-calc(26px);
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.content-section-component {
  h3 {
    font-size: rem-calc(18px);
    line-height: rem-calc(28px);
    letter-spacing: $letter-spacing-p;
    font-weight: $font-weight-bold;
  }
  a.button {
    margin-bottom: 40px;
  }
  ul {
    li {
      color: $color-text-primary;
      font-size: rem-calc(16px);
      line-height: 1.875rem;
    }
  }
}

.content-well-component {
  h1, h2, h3 {
    font-family: $font-family-aleo;
  }
  h4, h5 {
    font-family: $font-family;
  }
  h1 {
    font-size: rem-calc(38px);
  }
  h2 {
    font-size: rem-calc(28px);
  }
  h3 {
    font-size: rem-calc(22px);
  }
  h4 {
    font-size: rem-calc(16px);
  }
  h5 {
    font-size: rem-calc(14px);
    text-transform: uppercase;
    font-weight: $font-weight-normal;
  }
  p {
    font-size: rem-calc(14px);
    color: $color-text-primary;
  }
  .cell {
    span {
      color: $color-text-primary;
      font-size: rem-calc(18px);
    }
  }

  ul {
    margin-top: 30px;
    li {
      list-style-type: none;
      color: $color-text-primary;
      margin-bottom: 30px;
      font-size: rem-calc(18px);
      margin-top: 0px;
      font-weight: $font-weight-normal;
      position: relative;
      &:before {
        color: $color-primary;
        content: "\e902";
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: -30px;
        top: 6px;
        font-size: rem-calc(9px);
      }
    }
  }
  ol {
    list-style: none;
    padding-left: 50px;
    li {
        margin: 0 0 10px 0;
        margin-bottom: 50px;
        counter-increment: my-awesome-counter; 
        position: relative;
        color: $color-text-primary;
        font-size: rem-calc(18px);
        line-height: rem-calc(26px);
        &:before {
            position: absolute;
            background-color: #eaebf3;
            content: counter(my-awesome-counter) ". ";
            color: $color-primary;
            font-weight: bold;
            font-size: rem-calc(18px);
            left: -50px;
            text-align: center;
            padding: 3px 7px;
            top: -5px;
          }
        }
      }
}

.content-section-component {
  ul {
    li {
      font-size: rem-calc(14px);
    }
  }
}

.featured-content-section {
  ul {
    li {
      font-size: rem-calc(14px);
      line-height: rem-calc(26px);
      color: $color-text-primary;
    }
  }
}

.detailed-content-image-section {
  ol {
      list-style: none;
      padding-left: 30px !important;
      li {
          margin: 0 0 10px 0;
          margin-bottom: 20px;
          counter-increment: my-awesome-counter; 
          position: relative;
          color: $color-text-primary;
          font-size: rem-calc(16px);
          line-height: rem-calc(26px);
          &:before {
              position: absolute;
              background-color: #eaebf3;
              content: counter(my-awesome-counter) ". ";
              color: $color-primary;
              font-weight: bold;
              font-size: rem-calc(16px);
              left: -30px;
              // height: 20px;
              // width: 20px;
              text-align: center;
              padding: 3px 7px;
              top: -5px;
            }
      }
  }
  ul {
      list-style: none;
      padding-left: 30px;
      li {
          cursor: pointer;
          margin: 0 0 10px 0;
          margin-bottom: 20px;
          position: relative;
          color: $color-text-primary;
          font-size: rem-calc(14px);
          line-height: rem-calc(26px);
          &.active {
            &:before {
                content: "\e90b";
                transform: rotate(90deg);
              }
          }
          &.show {
            ul li {
              display: block;
            }
          }
          ul {
            padding-left:0px;
            margin-top: 10px;
            margin-bottom: 50px;
            li {
              display: none;
              &:before {
                display:none;
              }
            }
          }
          &:before {
              font-family: 'icomoon' !important;
              speak: none;
              font-style: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              position: absolute;
              background-color: #eaebf3;
              content: "\e90e";
              color: $color-primary;
              font-weight: $font-weight-normal;
              font-size: rem-calc(16px);
              left: -30px;
              // height: 20px;
              // width: 20px;
              text-align: center;
              padding: 7px 7px;
              top: 3px;
              font-size: rem-calc(11px);
          }
          &.show {
              &:before {
                  content: "\e90b";
                  transform: rotate(90deg);
              }
          }
          h3 {
              font-weight: $font-weight-normal;
              font-size: 14px;
              margin-bottom: 0px;
              color: $color-text-primary;
          }
      }
  }
}


//component section overrides medium
@media (min-width: map-get($grid-breakpoints, medium)) {
  .button {
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
  p {
    font-size: rem-calc(18px);
    line-height: rem-calc(25px);
    color: $color-text-primary;
  }
  h2 {
    mark {
      letter-spacing: $letter-spacing-heading;
      padding: 20px 15px 15px;
    }
  }
  h1 {
    u {
      &:after {
        border-bottom: 4px solid $color-primary;
        top: -4px;
      }
    }
  }
  h3 {
    font-size: rem-calc(24px);
    line-height: rem-calc(28px);
    u {
      &:after {
        border-bottom: 4px solid $color-primary;
        top: -4px;
      }
    }
    
  }
  figure.image, img {
    &.float-right, &[align="right"], &[style="float:right"] {
      max-width: rem-calc(350px);
    }
    
    &.float-left, &[align="left"], &[style="float:left"] {
      max-width: rem-calc(350px);
    }
  } 

  .detailed-content-image-section {
    ul {
      li {
        font-size: rem-calc(18px);
        ul {
          margin-top: 20px;
          margin-bottom: 50px;
        }
      }
    }
  }

  .image-carousel {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100% !important;
  }
  
  .featured-content-section {
    .content {
      div {
        span {
          &.single {
            width: 100%;
          }
          width:50%;
          &:first-child {
              padding-right: 20px;
          }
          &:last-child {
              padding-left: 20px;
          }
        }
      }
    }
    p {
        font-size: rem-calc(18px);
        line-height: rem-calc(30px);
    }
    ul {
      li {
        font-size: rem-calc(18px);
        line-height: rem-calc(30px);
      }
    }
  }

  .global-hero-block {
    h2 {
      strong {
          color: $color-white;
          background-color: $color-primary;
          font-weight: $font-weight-bold;
          display: inline-block;
          margin-top: 10px;
          font-family: $font-family-aleo;
          @include font-aleo-heading-mobile();
          padding: 6px 12px 5px;
          text-shadow: none;
      }
    }
  }
 
  .content-well-component {
    h1, h2, h3 {
      font-family: $font-family-aleo;
    }
    h4, h5 {
      font-family: $font-family;
    }
    h1 {
      font-size: rem-calc(68px);
    }
    h2 {
      font-size: rem-calc(48px);
    }
    h3 {
      font-size: rem-calc(32px);
    }
    h4 {
      font-size: rem-calc(18px);
    }
    h5 {
      font-size: rem-calc(14px);
    }
    p {
      font-size: rem-calc(14px);
      color: $color-text-primary;
    }
    ul {
      margin-top: 30px;
      li {
        list-style-type: none;
        color: $color-text-primary;
        margin-bottom: 30px;
        font-size: rem-calc(18px);
        margin-top: 0px;
        font-weight: $font-weight-normal;
        position: relative;
        &:before {
          color: $color-primary;
          content: "\e902";
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
          left: -30px;
          top: 6px;
          font-size: rem-calc(9px);
        }
      }
    }
    ol {
      list-style: none;
      padding-left: 50px;
      li {
          margin: 0 0 10px 0;
          margin-bottom: 50px;
          counter-increment: my-awesome-counter; 
          position: relative;
          color: $color-text-primary;
          font-size: rem-calc(18px);
          line-height: rem-calc(26px);
          &:before {
              position: absolute;
              background-color: #eaebf3;
              content: counter(my-awesome-counter) ". ";
              color: $color-primary;
              font-weight: bold;
              font-size: rem-calc(18px);
              left: -50px;
              text-align: center;
              padding: 3px 7px;
              top: -5px;
            }
          }
        }
  }

  .content-section-component {
    ul {
      li {
        font-size: rem-calc(18px);
      }
    }
  }

  .bulleted-content-section {
    ul {
        display: flex;
        flex-flow: row wrap;
        padding-left: 5px;
        li {
            flex-basis: calc(50% - 40px); 
            margin: 0 20px 40px;
            font-size: rem-calc(18px);
            padding-right: 20px;
            ul {
              display: block;
              margin-top: 10px;
              li {
                margin: 0px;
                font-size: rem-calc(18px);
                margin-bottom: 30px;
              }
            }
        }
    }
  }

  .icon-website-icon {
      display: inline-block;
      top: 9px;
  }
}


//ie11
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  .bulleted-content-section ul li {
       flex-basis:calc(43% - 10px) !important;
  }
  .product-tiles--tile__wrapper {
    .content {
      width: 100% !important;
      display: table !important;
      text-align: center !important;
      p.heading {
        display: table-cell !important;
        vertical-align: middle !important;  
      }
    }
  }
}

//ie16+
@supports (-ms-ime-align:auto) {
  .bulleted-content-section ul li {
    flex-basis:40% !important;
  }
  .subsidiary-locations-section ul li {
    flex-basis: 40% !important;
  }
}
