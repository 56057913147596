// Global variables for use in components.  To import in a component's scss file, use '@import '../../../sass/variables';'
@import './vendor/foundation-settings';

$global-font-size: 16px;

//colors
$color-primary: #2F3F90;
$color-primary-hover: #9092c1;
$color-secondary: #f1f1f1;
$color-page-offset: #f3f3f3;
$color-background-light: #f1f1f1;
$color-background-medium: #e0e2e4;
$color-background-dark: #242424;
$color-borders: #c3c3c3;
$color-borders-light: #DDDEE0;
$color-borders-extra-light: #F4F4F4;
$color-error: #ff6e40;
$color-white: #ffffff;
$color-black: #000000;
$color-mauve-background: #EAEBF3;

//text colors
$color-text-primary: #929286;
$color-text-emphasis: #1F1F1F;
$color-text-off: #929286;
$color-text-light: #BABABA;

// Pulled from foundation-settings.  If changes need to be made, edit the foundation settings file.
$grid-breakpoints: $breakpoints;

// Pulled from foundation-settings.  If changes need to be made, edit the foundation settings file.
$gutters: $grid-margin-gutters;

//Font Families
$font-family-secondary: 'Source Sans Pro', sans-serif;
$font-family-aleo: 'Aleo', sans-serif;
$font-family: 'Source Sans Pro', sans-serif;


//Heading Font Sizing
$font-size-h1: rem-calc(45px);
$font-size-h2: rem-calc(32px);

//Font Weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;

$box-shadow: 2px 2px 5px 0 rgba(76, 76, 76, 0.33);

$letter-spacing-button-mobile: 1.5px;
$letter-spacing-button: 1.3px;
$letter-spacing-heading: 1px;
$letter-spacing-heading-mobile: .76px;
$letter-spacing-p: .44px;
$letter-spacing-p-mobile: .55px;

$global-gutter: 0px 100px;
