.generic-content-page {
  // This should only apply for IE10+
  -ms-overflow-x: hidden;
  overflow-x: hidden;
  margin-left: -20px;
  margin-right: -20px;
  max-width: 100vw;

  h2 {
    font-size: rem-calc(24px);
  }
  p {
      font-size: rem-calc(14px);
  }
  .detailed-content-image-section {
    img {
        margin-bottom: 40px;
        box-shadow: 10px 10px 0px $color-page-offset;
    }
    h2 {
        font-size: rem-calc(24px);
    }
    h3 {
      font-size: rem-calc(18px);
      margin-bottom: 30px;
      font-family: $font-family-secondary;
    }
    p {
        font-size: rem-calc(14px);
        margin-bottom: 50px;
        line-height: rem-calc(30px);
    }
    ol {
        list-style: none;
        padding-left: 50px;
        li {
            margin: 0 0 10px 0;
            margin-bottom: 50px;
            counter-increment: my-awesome-counter; 
            position: relative;
            color: $color-text-primary;
            font-size: rem-calc(14px);
            line-height: rem-calc(26px);
            :before {
                position: absolute;
                background-color: #eaebf3;
                content: counter(my-awesome-counter) ". ";
                color: $color-primary;
                font-weight: bold;
                font-size: rem-calc(16px);
                left: -50px;
                text-align: center;
                padding: 3px 7px;
                top: -5px;
              }
        }
    }
  }
}
@media (min-width: map-get($grid-breakpoints, medium)) {
  .generic-content-page {
    h2 {
        font-size: rem-calc(32px);
    }
    p {
        font-size: rem-calc(18px);
        line-height: rem-calc(30px);
    }
    .detailed-content-image-section {
        h2 {
            font-size: rem-calc(32px);
        }
        p {
            font-size: rem-calc(18px);
        }
        ol {
          li {
            font-size: rem-calc(18px);
          }
        }
    }
  }
}
/*
.generic-content-page {
  .right-rail {
    margin-top: rem-calc(16px);
  }

  &, .rich-text {
    h1 {
      font-size: rem-calc(28px);
      line-height: rem-calc(32px);
      margin-bottom: 10px;
      margin-top: 0;
    }

    h2 {
      font-size: rem-calc(24px);
    }

    h3 {
      margin-bottom: 0;
    }

    .large-3 {
      padding-bottom: 20px;
    }
    p, ol, ul {
      color: $color-text-primary;
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
      margin: rem-calc(15px 0 40px);
      padding: 0;
    }


    ol, ul {
      padding-left: rem-calc(18px);
    }
  }
  img, figure.image {
    &.float-left, &[align="left"], &[style="float:left"] {
      padding: 0 0 30px 0;
    }

    &.float-right, &[align="right"], &[style="float:right"] {
      padding: 0 0 30px 0px;
    }
  }
  @media (min-width: map-get($grid-breakpoints, medium)) {
    &, .rich-text {
      h1 {
        font-size: rem-calc(44px);
        margin-top: 20px;
      }

      h2 {
        font-size: rem-calc(28px);
      }
      h3 {
        font-size: rem-calc(24px);
        line-height: rem-calc(28px);
      }
      p, ol, ul {
        font-size: rem-calc(18px);
        line-height: rem-calc(26px);
      }
    }
    
    figure.image {
      figcaption {
        font-size: rem-calc(13.5px);
      }
    }

    img, figure.image {
      &.float-left, &[align="left"], &[style="float:left"] {
        float: left!important;
        padding: 0 30px 30px 0px;
      }

      &.float-right, &[align="right"], &[style="float:right"] {
        float: right!important;
        padding: 0 0 30px 30px;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, large)) {
    .right-rail {
      margin-top: 0;
    }
  }
}

*/
