button, a{
  &.mat-button,
  &.mat-flat-button,
  &.mat-icon-button,
  &.mat-stroked-button,
  &.mat-raised-button {
    border-radius: 0;
    padding: rem-calc(13px 50px) !important;
    font-size: rem-calc(16px) !important;
    font-weight: $font-weight-heavy;
    line-height: rem-calc(26px) !important;
    text-transform: uppercase;
  
    * {
      font-size: rem-calc(16px) !important;
      font-weight: $font-weight-heavy;
    }
  
    &.mat-primary {
      color: $color-primary;
    }
  }
  
  &.mat-raised-button.mat-primary {
    background-color: $color-primary;
    color: $color-white;

    &.loading {
      background-color: $color-white;
    }
  }
  
  &.underline {
    text-decoration: underline;
  }
}
